import React from 'react';
import { Script } from 'gatsby';
import { isKiosk } from '../utils/isKiosk';

const CookieScript = () => {
  const isKioskMode = isKiosk();
  if (isKioskMode) return null;
  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={process.env.ONETRUST_COOKIE_DOMAIN_SCRIPT}
        className={process.env.ONETRUST_COOKIE_DOMAIN_SCRIPT}
        strategy="idle"
      />
      <Script
        src="/OneTrustCookieScript.js"
        type="text/javascript"
        className="onetrust"
        strategy="idle"
      />
    </>
  );
};

export default CookieScript;
